@media (min-width: 1200px) {
  .row-StatusReport .col-lg-2 {
    width: 20%;
    max-width: 20%;
    flex: 0 0 20%;
  }
}

.row-StatusReport .component-Box {
  border: 1px solid #e7eaec;
  border-top: 0;
}

.row-StatusReport .report-List {
  list-style: none;
  padding-left: 0;
  margin-right: 0;
}

.row-StatusReport .report-Item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
}
.row-StatusReport .report-Item i.fa {
  font-size: 1rem;
  padding-top: 3px;
  margin-right: 10px;
}
.row-StatusReport .report-Item h3 {
  font-size: 0.9rem;
  margin-top: 0;
  margin-bottom: 5px;
}
.row-StatusReport .report-Item h3 small {
  display: block;
  font-style: italic;
  margin-top: 5px;
  font-size: 0.75em;
}
.row-StatusReport .report-Item p,
.row-StatusReport .report-Item span {
  font-size: 0.75rem;
  margin-bottom: 5px;
}

.component-Alert {
  display: flex;
}
.component-Alert .alert-Resource {
  width: 100px; 
  display: block; 
  margin-right: 2rem;
}
.component-Alert .alert-Resource img {
  width: 100%;
  border-radius: 50%;
  height: auto;
  display: block;
}
.component-Alert .alert-Body {
  flex: 1;
}

.component-Alert .alert-Body .assistance-message {
  margin-top: 20px;
}

.component-Alert .alert-Response .form-group {
  margin-top: 1rem;
}
.component-Alert .alert-Response input {
  margin-right: 0.5rem;
  cursor: pointer;
}
.component-Alert .alert-Response label {
  cursor: pointer;
}

.react-notification-root {
  position: fixed;
  z-index: 9000;
  pointer-events: none;
  width: 88% !important;
  height: 100%;
}
@media (width: 1440px) {
  .react-notification-root {
    width: 84% !important;
  }
}
@media (width: 1024px) {
  .react-notification-root {
    width: 78% !important;
  }
}

.notification-container-bottom-right {
  bottom: 175px;
}
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  z-index: 1000;
}

.popup-content {
  max-height: 400px; /* Adjust as needed */
  overflow-y: auto;
}

.close-btn {
  margin-top: 10px;
  cursor: pointer;
}
 .popup_content {
  position: relative;
    background: #f3f3f4;
    font-size: 15px;
    border-radius: .25em;
    padding: 5px;
 }
